import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import graphqlApiUrl from '../graphqlApiUrl';

const post = <T, U = unknown>(
	data?: U,
	config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<T>> => {
	const url = graphqlApiUrl();
	return axios.post(url, data, config);
};

export default {
	post,
};
