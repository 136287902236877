import { useAppSelector } from '@redux/hooks';
import { getPageType } from '@redux/page';

export const useSourcePage = () => {
	const pageType = useAppSelector((state) =>
		getPageType(state),
	) as Page.PageType;
	const isPaid = useAppSelector((state) => state.vendor?.vendor?.isPaid);
	switch (pageType) {
		case 'best of weddings':
			return 'BOW';
		case 'category':
			return 'category landing page';
		case 'city':
			return 'category results';
		case 'state':
			return 'state landing page';
		case 'storefront':
			return isPaid ? 'paid storefront' : 'unpaid storefront';
		case 'review wedding vendors':
			return 'Review Hub';
		default:
			return '';
	}
};
