export const BOW_FACET_ID = 'c59bdf9a-6a9e-423c-8332-3c3aca6df62b';
export const PRICE_RANGE_FACET_ID = 'c6046f9a-5780-4b76-8ace-731ff1b7ae62';
export const STARTING_PRICE_RANGE_FACET_ID =
	'd152eee4-6a3f-4116-9226-8a977d274b85';
export const INEXPENSIVE_FACET_ID = '7dd029e6-1879-4381-81b9-5dc4998fbdec';
export const AFFORDABLE_FACET_ID = '82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2';
export const MODERATE_FACET_ID = 'b08f3a88-a519-409a-91cc-7477a613a5be';
export const LUXURY_FACET_ID = '3a18c67f-f9ac-4ff9-80b8-61ed88bc4cd1';
export const VENUE_AMENITIES_FACET_ID = 'c1b91e1f-a8ac-4896-9e7f-fcbbad603c5b';
export const GUEST_CAPACITY_FACET_ID = '44ed7871-6e3c-4105-806a-4fd9efe72c4e';
