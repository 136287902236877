import fieldsLimited from '../Storefront/facets/limited/fieldsLimited';

const fragment = `
fragment FacetsRecurseLimited on Facets {
  facets {
    ...FacetFieldsLimited
    facets{
      ...FacetFieldsLimited
    }
  }
}
${fieldsLimited}
`;

export default fragment;
