import {
	AFFORDABLE_FACET_ID,
	INEXPENSIVE_FACET_ID,
	LUXURY_FACET_ID,
} from '@components/vendorCard/constants';
import type { AppliedFilterPillProps } from 'types/search';

interface CategoryMap {
	[key: string]: string;
}

export const categoryMap: CategoryMap = {
	'dd6dc992-8647-4bba-a17e-feaa684d03b0': 'FLO',
	'1572f50a-93b6-40ea-9492-d8cec37f0c44': 'REC',
	'a520ad92-ff3f-4869-a382-92c87a5d809e': 'WPH',
	'4c1e13e9-d500-4f3f-870e-d919beda43cf': 'VID',
};

export const vanityGuids = [
	AFFORDABLE_FACET_ID,
	INEXPENSIVE_FACET_ID,
	LUXURY_FACET_ID,
];

export interface RangeFilter {
	field: string;
	gte?: number;
	lte?: number;
}

interface RangeFiltersMap {
	[key: string]: RangeFilter;
}

export const buildRangeFilters = (applied: AppliedFilterPillProps[]) => {
	const rangeFilter: RangeFilter = {
		field: 'STARTING_PRICE',
	};
	for (const filter of applied) {
		const { categorySlug, value } = filter;
		if (categorySlug === 'minPrice') {
			rangeFilter.gte = Number(value);
		}
		if (categorySlug === 'maxPrice') {
			rangeFilter.lte = Number(value);
		}
	}
	return rangeFilter.gte || rangeFilter.lte ? [rangeFilter] : [];
};

export const rangeFiltersMap: RangeFiltersMap = {
	'REC-7dd029e6-1879-4381-81b9-5dc4998fbdec': {
		field: 'STARTING_PRICE',
		gte: 0,
		lte: 2500,
	},
	'REC-82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2': {
		field: 'STARTING_PRICE',
		gte: 2500,
		lte: 5000,
	},
	'REC-3a18c67f-f9ac-4ff9-80b8-61ed88bc4cd1': {
		field: 'STARTING_PRICE',
		gte: 10000,
	},
	'WPH-82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2': {
		field: 'STARTING_PRICE',
		gte: 0,
		lte: 1000,
	},
	'VID-82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2': {
		field: 'STARTING_PRICE',
		gte: 0,
		lte: 1500,
	},
	'VID-3a18c67f-f9ac-4ff9-80b8-61ed88bc4cd1': {
		field: 'STARTING_PRICE',
		gte: 2500,
	},
	'FLO-82d0bc5a-8269-4b3b-ba2f-ba833f9c0fe2': {
		field: 'STARTING_PRICE',
		gte: 1000,
		lte: 1999,
	},
};
