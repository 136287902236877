export default {
	DEFAULT_VENDOR_LIMIT_SHOW: 1 as number,
	DEFAULT_VENDOR_LIMIT_FETCH: 4 as number,

	FETCH_SIMILAR_VENDORS: 'FETCH_SIMILAR_VENDORS',
	FETCH_SIMILAR_VENDORS_SUCCESS: 'FETCH_SIMILAR_VENDORS_SUCCESS',
	FETCH_SIMILAR_VENDORS_ERROR: 'FETCH_SIMILAR_VENDORS_ERROR',

	FETCH_BOW_VENDORS: 'FETCH_BOW_VENDORS',
	FETCH_BOW_VENDORS_SUCCESS: 'FETCH_BOW_VENDORS_SUCCESS',
	FETCH_BOW_VENDORS_ERROR: 'FETCH_BOW_VENDORS_ERROR',

	FETCH_PVREC_ANON_VENDORS: 'FETCH_PVREC_ANON_VENDORS',
	FETCH_PVREC_ANON_VENDORS_SUCCESS: 'FETCH_PVREC_ANON_VENDORS_SUCCESS',
	FETCH_PVREC_ANON_VENDORS_ERROR: 'FETCH_PVREC_ANON_VENDORS_ERROR',

	FETCH_PVREC_CROSS_CAT_VENDORS: 'FETCH_PVREC_CROSS_CAT_VENDORS',
	FETCH_PVREC_CROSS_CAT_VENDORS_SUCCESS:
		'FETCH_PVREC_CROSS_CAT_VENDORS_SUCCESS',
	FETCH_PVREC_CROSS_CAT_VENDORS_ERROR: 'FETCH_PVREC_CROSS_CAT_VENDORS_ERROR',
} as const;
