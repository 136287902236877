import { atomWithStore } from 'jotai-redux';
import { useHydrateAtoms } from 'jotai/utils';
import type { WritableAtom } from 'jotai/vanilla';
import type { ReactElement } from 'react';
import { useStore } from 'react-redux';
import { DO_NOT_IMPORT_nested_redux_atom } from '../jotai/redux-atoms';

interface HydrateAtomsProps {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	initialValues: [WritableAtom<unknown, any[], any>, unknown][];
	children: ReactElement;
}

export const HydrateAtoms = ({
	initialValues,
	children,
}: HydrateAtomsProps) => {
	const store = useStore();
	const tempReduxAtom = atomWithStore(store);
	useHydrateAtoms([
		...initialValues,
		[DO_NOT_IMPORT_nested_redux_atom, tempReduxAtom],
	]);
	return children;
};
