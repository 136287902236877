import {
	graphqlApiUrl as graphqlApiUrlDefault,
	graphqlApiUrlK8,
} from '../../../settings';

export default function graphqlApiUrl() {
	const backEnd = typeof exports !== 'undefined';
	if (backEnd && process.env.K8 && process.env.NODE_ENV === 'production') {
		return graphqlApiUrlK8;
	}

	return graphqlApiUrlDefault;
}
