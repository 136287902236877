export const GPT_AD_LOADED = '@fe-marketplace/gptAds/gptAdSlotFilled';

export default function gptAdsReducer(state = {}, { payload, type }) {
	switch (type) {
		case GPT_AD_LOADED:
			return { ...state, ...payload };
		default:
			return state;
	}
}

export function updateAdSlotFillStatus(match) {
	const { id, adSlotFilled } = match;

	return (dispatch) =>
		dispatch({
			type: GPT_AD_LOADED,
			payload: { [id]: { adSlotFilled } },
		});
}

export const getGptAds = (state) => state.gptAds;
export const getAdSlotFillStatus = (state, adId) =>
	state.gptAds[adId] && state.gptAds[adId].adSlotFilled;
