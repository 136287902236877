/* eslint-disable you-dont-need-lodash-underscore/flatten */
import flatten from 'lodash/flatten';
import VendorHelper from '../../../helpers/vendor';
import * as helpers from './helpers';

const { DOMParser } = require('xmldom');

export const GUEST_CAPACITY_FACET_ID = '44ed7871-6e3c-4105-806a-4fd9efe72c4e';
const PRICE_RANGE_FACET_ID = 'c6046f9a-5780-4b76-8ace-731ff1b7ae62';
const VENUE_AMENITIES_FACET_ID = 'c1b91e1f-a8ac-4896-9e7f-fcbbad603c5b';
const STARTING_PRICE_RANGE_FACET_ID = 'd152eee4-6a3f-4116-9226-8a977d274b85';

const DEFAULT_STOREFRONT_CARD = {
	id: null,
	url: null,
};

// This is only for Favorites.
// We don't use the existing storefrontCard default because it's transparent,
// and the legacy Favorites page is very basic in terms of styling. (the newer page doesn't even use these images)
const DEFAULT_FAVORITES_STOREFRONT_CARD = {
	id: '35018e98-012a-4637-b0dd-67c8cfeb40df',
	url: 'https://media-api.theknot.com/images/35018e98-012a-4637-b0dd-67c8cfeb40df',
};

/** @param {Vendor.Raw} vendor */
const uriData = ({ siteUrls }) => {
	// we are requesting via graphql on storefront v3 but reviews hub uses the legacy structure,
	// this is a protection against siteUrls being both an object and an array in these different contexts
	if (siteUrls && siteUrls.length) {
		return siteUrls.find((url) => url.siteId === 90);
	}
	return siteUrls;
};

const locationSlug = (vendor) => {
	const addr = helpers.address(vendor);
	const vendorCity =
		addr.city && addr.city.trim().replace(/\s+/g, '-').toLowerCase();
	const vendorState = addr.state && addr.state.trim().toLowerCase();
	return vendorCity && vendorState ? `${vendorCity}-${vendorState}` : '';
};

const categoryInfoPlural = (vendor) => vendor?.categoryInfo?.plural || {};
const categoryInfoSingular = (vendor) => vendor?.categoryInfo?.singular || {};

const getBreadcrumbInfo = (vendor) => {
	// TODO: Add tests

	return {
		cityUrl: `/marketplace/${categoryInfoPlural(vendor).slug}-${locationSlug(
			vendor,
		)}?sort=featured`,
	};
};

const serviceArea = (vendor) => {
	const { location } = vendor;
	return location?.serviceArea || null;
};

/** @param {Vendor.Raw} vendor */
const email = ({ emails }) => {
	const primaryEmail = emails?.find(
		(vendorEmail) => vendorEmail.type === 'PRIMARY',
	);
	return primaryEmail?.address || '';
};

/** @param {Vendor.Phone[]} phones */
const getPrimaryPhone = (phones) =>
	phones?.find((phoneNumber) => phoneNumber.type === 'PRIMARY');

/** @param {Vendor.Raw} vendor */
const phone = ({ phones }) => {
	const primaryPhone = getPrimaryPhone(phones);
	return primaryPhone?.number || '';
};

/** @param { Vendor.Raw } vendor */
const extension = ({ phones }) => {
	const primaryPhone = getPrimaryPhone(phones);
	return primaryPhone?.extension || '';
};

/** @type (socialMedia: Vendor.SocialMedia[], code: Vendor.SocialMediaCode) => Vendor.SocialMedia */
const getSocialMedia = (socialMedia, code) =>
	socialMedia?.find((social) => social.code === code);

/** @param { Vendor.Raw } vendor */
const social = ({ socialMedia }) => {
	const facebookUrl = getSocialMedia(socialMedia, 'FBURL')?.value || '';
	const twitterUsername =
		getSocialMedia(socialMedia, 'TWITTERUSERNAME')?.value || '';
	const instagramUsername =
		getSocialMedia(socialMedia, 'INSTAGRAMUSERNAME')?.value || '';
	const pinterestUsername =
		getSocialMedia(socialMedia, 'PINTERESTUSERNAME')?.value || '';

	return {
		facebookUrl,
		twitterUsername,
		instagramUsername,
		pinterestUsername,
	};
};

/**
 * @param {Vendor.Raw} vendor
 * @param {Vendor.Facet[]} vendor.facets
 * @param {string} facetId
 */
const facetFlattener = ({ facets }, facetId) => {
	const facet = facets?.find((singleFacet) => singleFacet.id === facetId);

	if (facet) {
		return flatten(
			facet.facets.map((innerFacet) => {
				if (innerFacet.facets.length) {
					return innerFacet.facets.map((innerMostFacet) => ({
						id: innerMostFacet.id,
						name: innerMostFacet.name,
					}));
				}
				return { id: innerFacet.id, name: innerFacet.name };
			}),
		);
	}
	return [];
};

const stars = (vendor) =>
	helpers.roundReviewRating(vendor?.reviewSummary?.overallRating || 0);

const isPaid = (vendor) => vendor.purchaseStatus === 'PAID';

const unescape = (text) => {
	const textToEscape = text || '';
	const domText = new DOMParser().parseFromString(
		`<div>${textToEscape}</div>`,
		'text/html',
	);
	if (!!domText && !!domText.documentElement) {
		return domText.documentElement.textContent;
	}
	return textToEscape;
};

/**
 * Decorates a raw vendor (from the API response)
 * @class
 * @param {Vendor.Raw} vendor
 */
function Vendor(vendor) {
	this.id = vendor.id;
	this.displayId = vendor.displayId;
	this.locationId = vendor.locationId;
	this.name = unescape(vendor.name);
	this.description = unescape(vendor.description);
	this.headline = unescape(vendor.headline);
	this.uriData = uriData(vendor);
	this.websiteUrl = vendor.websiteUrl || '';
	this.displayWebsiteUrl = vendor.displayWebsiteUrl || '';
	this.purchaseStatus = vendor.purchaseStatus;
	this.claimedStatus = vendor.claimedStatus;
	this.adTier = vendor.adTier;
	this.vendorTier = vendor.vendorTier;
	this.designers = vendor.designers;
	this.photoSummary = vendor.photoSummary;
	this.logo = vendor.logo;
	this.email = email(vendor);
	this.phone = phone(vendor);
	this.extension = extension(vendor);
	this.social = social(vendor);
	this.siteUrls = vendor.siteUrls;
	this.facets = vendor.facets;
	this.priceRange = helpers.findFacet(vendor, PRICE_RANGE_FACET_ID);
	this.guestCapacity = helpers.capacityLimit(
		helpers.findFacet(vendor, GUEST_CAPACITY_FACET_ID),
	);
	this.amenities = facetFlattener(vendor, VENUE_AMENITIES_FACET_ID);
	this.awards = helpers.awardList(vendor);
	this.mediaSummary = vendor.mediaSummary;
	this.bio = vendor.bio;
	if (vendor.bio && vendor.bio.description) {
		this.bio.description = unescape(vendor.bio.description);
	}
	this.breadcrumbInfo = getBreadcrumbInfo(vendor);
	this.marketCode = vendor.marketCode;
	this.categoryCode = vendor?.categories?.[0]?.code || '';
	this.addOns = vendor.addOns;
	this.settings = vendor.settings;
	this.pledges = vendor.pledges;
	this.stars = stars(vendor);
	this.starCount = this.stars;
	this.reviewsCount = vendor.reviewSummary ? vendor.reviewSummary.count : 0;
	this.address = helpers.address(vendor);
	this.locationSlug = locationSlug(vendor);
	this.storefrontCard = vendor.storefrontCard || DEFAULT_STOREFRONT_CARD;
	this.favoritesStorefrontCard =
		vendor.storefrontCard || DEFAULT_FAVORITES_STOREFRONT_CARD;
	this.categorySlug = categoryInfoPlural(vendor).slug || '';
	this.categoryPluralTerm = categoryInfoPlural(vendor).term || '';
	this.categorySingularTerm = categoryInfoSingular(vendor).term || '';
	this.vendorId = vendor.vendorId;
	this.accountId = vendor.accountId;
	this.accountStatus = vendor.accountStatus;
	this.serviceArea = serviceArea(vendor);
	/* exposes this property for photographer category only */
	this.startingPriceRange = helpers.findFacet(
		vendor,
		STARTING_PRICE_RANGE_FACET_ID,
	);
	this.isPaid = isPaid(vendor);
	// V1 Seach Card Logic:
	// has any BOW/HOF award from *any* year
	this.hasAward = VendorHelper.hasAward(vendor);
	this.dek = vendor.dek;
	this.completionScore = vendor.statistics?.completionScore;
	return this;
}

export default Vendor;
