const mediaFragment = `
fragment MediaItems on MediaItem {
  ... on MediaPhoto {
    id
    width
    height
    url
    mediaType
  }
  ... on MediaTour {
    id
    url
    mediaType
  }
}
`;

export { mediaFragment };
