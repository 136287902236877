import fields from '../Storefront/affiliates/fields';

const fragment = `
fragment AffiliatesRecurse on Affiliates {
  affiliates {
    ...AffiliateFields
    affiliates {
      ...AffiliateFields
    }
  }
}
${fields}
`;

export default fragment;
