const fragment = `
fragment AffiliateFields on Affiliates {
  id
  name
  description
  singular {
    slug
    term
  }
  plural {
    slug
    term
  }
  isSelected
}`;

export default fragment;
