export const findFacet = (vendor, facetId, source = '') => {
	if (!vendor.facets) {
		return '';
	}
	const foundFacet = vendor.facets?.find((facet) => facet.id === facetId);
	return foundFacet?.facets?.[0]?.name || '';
};

export const roundReviewRating = (number) => {
	const parsedNum = Number.parseFloat(number);
	if (parsedNum === 0) {
		return 0;
	}
	const roundedNumber = Math.round(parsedNum * 10) / 10;
	return Number.parseFloat(roundedNumber.toFixed(1));
};

export const capacityLimit = (guests) => {
	const range = guests.match(/\d+-(\d+)/);
	return range ? `Up to ${range[1]}` : guests;
};

const bowAndHofMapping = (affiliate) => {
	if (affiliate?.match(/(.*)Best of Weddings/)) {
		const year = affiliate.split(' ')[0];
		return `BOW${year}`;
	}
	if (affiliate?.match(/Hall of Fame/)) {
		return 'BOWHOF';
	}

	return false;
};

export const awardList = (vendor) => {
	const awards = vendor?.affiliates?.[0]?.affiliates || [];
	return awards.map((award) => bowAndHofMapping(award.name)).filter((a) => a);
};

export const address = (vendor) => {
	const addr = vendor?.location?.address;

	return {
		address: addr?.address1 || '',
		city: addr?.city || '',
		state: addr?.state || '',
		postalCode: addr?.postalCode || '',
		isPublic: addr?.isPublic || false,
	};
};
