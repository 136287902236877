import initialState from '@redux/experiments/initialState';
import { experiments } from '@settings';
import type { Id } from '@typings/experiments';
import { constructAnalyticsProperties } from '@utils/constructAnalyticsProperties';
import { noop } from '@utils/noop';
import analyticsWrapper from '@xo-union/tk-analytics';
import React, { useMemo } from 'react';
import type { Track } from 'types/analytics';
import type { FCWithChildren } from 'types/react-extended';

interface AnalyticsContextValues {
	track: Track;
}

interface Props {
	reduxExperiments?: Redux.Experiments;
}
export const AnalyticsContext = React.createContext<AnalyticsContextValues>({
	track: noop,
});

const AnalyticsProvider: FCWithChildren<Props> = ({
	children,
	reduxExperiments = initialState,
}) => {
	const tkwwFlipperIds = useMemo(() => {
		const { assignments, configurations } = reduxExperiments;
		if (!assignments[experiments.sortPill]) return {};
		const assignedVariants = Object.entries(assignments).reduce(
			(acc, [key, value]) => {
				const variantId = (configurations[key as Id]?.variants || []).find(
					(variant) => variant.variant_name === value,
				)?.variant_id;
				if (variantId) acc[key] = variantId;
				return acc;
			},
			{} as { [key: string]: string },
		);
		return { 'TKWW-Flipper-IDs': assignedVariants };
	}, [reduxExperiments]);

	const track: Track = (analyticsEvent, context) => {
		analyticsEvent.properties = {
			...analyticsEvent.properties,
			...tkwwFlipperIds,
		};

		if (process.env.NODE_ENV !== 'production') {
			// biome-ignore lint/suspicious/noConsoleLog: Logging analytics on QA
			console.log(
				`****Analytics event: ${analyticsEvent.event}****`,
				analyticsEvent,
			);
		}
		analyticsWrapper.track(
			analyticsEvent.event,
			constructAnalyticsProperties(analyticsEvent.properties),
			context,
		);
	};

	const value = {
		track,
	};

	return (
		<AnalyticsContext.Provider value={value}>
			{children}
		</AnalyticsContext.Provider>
	);
};

export default AnalyticsProvider;
